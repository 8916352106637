// TODO: слой использует shared, скорее всего, нужно его поднять выше
import type { QuoteType, SchemeType } from '@/shared/types/flowStore.types';
import { TFlowType, type Purchase } from '@/shared/analytics/GoogleAnalyticsService.types';
import { type IdType } from '@/shared/types/flowStore.types';
import { regionNames } from '../constants/regionNamesMapping';

const PRODUCT_LABEL = 'oman_carIns';

const routingMatches = {
  phone: 'mobile_number',
  'plate-number': 'enter_info',
  'date-of-birth': 'date_of_birth',
  'chassis-number': 'choose_chassis',
  'car-details': 'car_details',
  'vehicle-check': 'check_car_details',
  'start-policy-date': 'select_policy_date',
  'select-plan': 'select_plan',
  policyholder: 'policyholder',
  'insured-drivers': 'insured_drivers',
  quote: 'your_quote',
  'upload-documents': 'upload_documents',
  'renewal-login': 'enter_policy',
  'policy-information': 'policy_info',
  'insured-drivers-renewal': 'insured_drivers',
  'quote-renewal': 'your_quote',
};

enum productCodes {
  OD = '0110',
  TPL = '0100',
}

export class GoogleAnalyticsService {
  private send(event: string, payload: Record<string, unknown> = {}) {
    if (typeof window.gtag !== 'function') {
      console.info(`[QIC-Analytics] Event ${event} wasn't sent by GoogleAnalyticsService`);
      return;
    }

    const patchedPayload = this.objectToStringFix(payload);
    const args: ['event', string, any?] = ['event', event];
    if (patchedPayload) {
      args.push(patchedPayload);
    }
    window.gtag(...args);

    console.info(`[QIC-Analytics] Event ${event} is sent by GoogleAnalyticsService`);
  }

  private objectToStringFix(object: Record<string, unknown>) {
    if (!object) return null;
    let copy: any;
    try {
      copy = structuredClone(object);
    } catch (e) {
      console.log('Probablemente structuredClone receives Proxy objects');
      console.error(e);
      throw e;
    }
    for (const [key, value] of Object.entries(copy)) {
      if (typeof value === 'object' && value !== null) {
        copy[key].toString = function () {
          return JSON.stringify(this);
        };
      }
    }
    return copy;
  }

  private clickProceed(page: string, additionalParams: Record<string, unknown> = {}) {
    this.send('click_proceed', {
      product: PRODUCT_LABEL,
      page,
      ...additionalParams,
    });
  }

  private errorProceed(page: string, additionalParams: Record<string, unknown> = {}) {
    this.send('input_error', {
      product: PRODUCT_LABEL,
      page,
      ...additionalParams,
    });
  }
  proceedOnPhoneNumber() {
    this.clickProceed('mobile_number', {
      plan_name: 'TPL',
    });
  }

  proceedPlateAndBirth(plateFullInfo: string, flowType: TFlowType, pageName: string) {
    this.clickProceed(pageName, {
      product: PRODUCT_LABEL,
      plan_name: 'TPL',
      plate_number: plateFullInfo ?? 'don’t have',
      flow_type: flowType,
    });
  }

  proceedErrorEnterInfo(textError: string) {
    this.errorProceed('enter_info', {
      plan_name: 'TPL',
      text: textError,
    });
  }

  errorLicenseNumber(textError: string) {
    this.send('input_error', {
      product: PRODUCT_LABEL,
      plan_name: 'TPL',
      page: 'policyholder_info',
      input_field: 'license_number',
      value: textError,
    });
  }

  successLicenseNumberInfo(licenseNumber: string) {
    this.send('license_validated', {
      product: PRODUCT_LABEL,
      plan_name: 'TPL',
      page: 'policyholder_info',
      input_field: 'license_number',
      value: licenseNumber,
    });
  }
  viewPlans(plans: SchemeType[], entrypoint: string, isOfflineOnly: boolean) {
    const quotes = plans
      .filter(
        (item) => item.productcode === productCodes.OD || item.productcode === productCodes.TPL,
      )
      .map((item) => ({
        item_name: item.productcode === productCodes.OD ? 'Comprehensive' : 'TPL',
        price: item.price,
        item_variant: item.productcode === productCodes.OD ? 'OD' : 'TPL',
      }));

    this.send('view_item', {
      product: PRODUCT_LABEL,
      page: 'select_plan',
      flow: entrypoint,
      OD: isOfflineOnly ? 'only_offline' : 'available',
      items: quotes,
    });
  }

  selectPlan(productCode: string, price: number, entrypoint: string) {
    this.send('select_item', {
      product: PRODUCT_LABEL,
      page: 'select_plan',
      flow: entrypoint,
      items: [
        {
          item_name: productCode === productCodes.OD ? 'Comprehensive' : 'TPL',
          price: price,
          item_variant: productCode === productCodes.OD ? 'OD' : 'TPL',
        },
      ],
    });
  }

  showCovers(plan: QuoteType) {
    const itemName = plan.schemes[0].name;
    const quoteId = plan.quoteno;
    const price = plan.schemes[0].price;
    this.send('click_learn_more', {
      product: PRODUCT_LABEL,
      page: 'select_plan',
      item_name: itemName,
      plan_name: 'TPL',
      price,
      quote_id: quoteId,
    });
  }

  clickAddon(plan: QuoteType, addonName: string) {
    const itemName = plan.schemes[0].name;
    const quoteId = plan.quoteno;
    const price = plan.schemes[0].price;
    this.send('click_addon', {
      product: PRODUCT_LABEL,
      page: 'select_plan',
      item_name: itemName,
      addon_name: addonName,
      plan_name: 'TPL',
      price,
      quote_id: quoteId,
    });
  }

  downloadPolicyRenew(
    policyId: Undefinable<string>,
    isRenewal: boolean,
    quoteId: Nullable<string>,
  ) {
    this.send('policy_dowload', {
      category: PRODUCT_LABEL,
      product: 'renewal',
      page: 'new_policy',
      plan_name: 'TPL',
      policy_id: policyId,
      transaction_id: quoteId,
    });
  }

  downloadPolicy(policyId: Undefinable<string>, isRenewal: boolean) {
    this.send('click_download_policy', {
      product: PRODUCT_LABEL,
      plan_name: 'TPL',
      page: 'download_documents',
      policy_id: policyId,
      category: isRenewal ? 'renewal' : null,
    });
  }

  paymentReceiptRenewal(
    policyId: Undefinable<string>,
    isRenewal: boolean,
    quoteId: Nullable<string>,
  ) {
    this.send('click_payment_receipt', {
      category: PRODUCT_LABEL,
      product: 'renewal',
      page: 'new_policy',
      plan_name: 'TPL',
      policy_id: policyId,
      transaction_id: quoteId,
    });
  }

  paymentReceipt(policyId: Undefinable<string>, isRenewal: boolean) {
    this.send('click_payment_receipt', {
      product: PRODUCT_LABEL,
      plan_name: 'TPL',
      page: 'download_documents',
      policy_id: policyId,
      category: isRenewal ? 'renewal' : null,
    });
  }

  purchase(data: Purchase, isRenew?: boolean) {
    this.send('purchase', {
      product: PRODUCT_LABEL,
      plan_name: 'TPL',
      page: 'your_quote',
      category: isRenew ? 'renewal' : null,
      policy_id: data.policyId,
      transaction_id: data.transactionId,
      number_of_addons: data.addons,
      Chosen_plan: data.plan,
      chosen_card: 'cartc',
      total_price: data.totalPrice,
      addons_price: data.addonsPrice,
      list_of_addons: data.listOfAddons,
      coupon: '',
    });
  }

  proceedCheckCarDetails(
    chassisNumber: string,
    plateNumber: string,
    brand: string,
    model: string,
    modelYear: Nullable<number>,
    registrationYear: Nullable<number>,
    vehicleUsage: string,
    vehicleType: string,
    seats: string,
    cylinders: string,
  ) {
    this.clickProceed('check_car_details', {
      plan_name: 'TPL',
      product: PRODUCT_LABEL,
      chassis_number: chassisNumber,
      plate_number: plateNumber,
      make: brand,
      model,
      model_year: modelYear,
      registration_year: registrationYear,
      vehicle_usage: vehicleUsage,
      vehicle_type: vehicleType,
      seats,
      cylinders,
      flow_type: TFlowType.RECOGNIZED_CHASSIS_NUMBER,
    });
  }

  notMyCarCheckCarDetails(
    model: string,
    brand: string,
    chassis_number: string,
    plate_number: string,
  ) {
    this.send('click_not_my_car', {
      product: PRODUCT_LABEL,
      plan_name: 'TPL',
      page: 'check_car_details',
      model: model,
      brand: brand,
      chassis_number: chassis_number,
      plate_number: plate_number,
      flow_type: TFlowType.MANUAL,
    });
  }

  proceedOnChassisNumber(chassisNumber: string, flowType: TFlowType) {
    this.clickProceed('choose_chassis', {
      product: PRODUCT_LABEL,
      plan_name: 'TPL',
      chassis_number: chassisNumber,
      flow_type: flowType,
    });
  }

  enterCarDetailsChassisNumber() {
    this.send('click_enter_car_details', {
      product: PRODUCT_LABEL,
      plan_name: 'TPL',
      page: 'choose_chassis',
      flow_type: TFlowType.MANUAL,
    });
  }

  proceedCarDetails(
    brand: string,
    model: string,
    modelYear: Nullable<number>,
    registrationYear: Nullable<number>,
    vehicleUsage: string,
    vehicleType: string,
    seats: string,
    cylinders: string,
    chassisNumber: string,
  ) {
    this.clickProceed('car_details', {
      product: PRODUCT_LABEL,
      plan_name: 'TPL',
      brand: brand,
      model: model,
      model_year: modelYear,
      registration_year: registrationYear,
      vehicle_usage: vehicleUsage,
      vehicle_type: vehicleType,
      seats,
      cylinders,
      chassis_number: chassisNumber,
      flow_type: TFlowType.MANUAL,
    });
  }

  proceedSelectPolicyStart() {
    this.clickProceed('select_policy_date', {
      plan_name: 'TPL',
    });
  }

  proceedPolicyHolderRenewal(policyId: Nullable<string>, license: Nullable<string>) {
    this.send('click_proceed', {
      category: PRODUCT_LABEL,
      product: 'renewal',
      page: 'policyholder_info',
      policy_id: policyId,
      license: license,
      plan_name: 'TPL',
    });
  }

  proceedPolicyHolder(dateOfBirth: string, yearOfDriving: string) {
    this.clickProceed('policyholder', {
      plan_name: 'TPL',
      page: 'policyholder',
      year_of_driving: yearOfDriving,
      date_of_birth: dateOfBirth,
    });
  }

  clickAddDriverRenewal() {
    this.send('add_driver', {
      product: 'renewal',
      page: 'insured_drivers',
      category: PRODUCT_LABEL,
    });
  }

  clickAddDriver() {
    this.send('add_driver', {
      product: 'oman_carIns',
      Page: 'insured_drivers',
      plan_name: 'TPl',
      category: null,
    });
  }

  proceedDriverRenew(policyId: Nullable<string>, license: Nullable<string>) {
    this.send('click_proceed', {
      category: PRODUCT_LABEL,
      product: 'renewal',
      page: 'insured_drivers',
      policy_id: policyId,
      license: license,
      plan_name: 'TPL',
    });
  }

  proceedDriver(numberOfDrivers: number, isRenew: boolean) {
    this.clickProceed('insured_drivers', {
      plan_name: 'TPL',
      num_drivers: numberOfDrivers,
      category: isRenew ? 'renewal' : null,
    });
  }

  deleteDriver(isRenew: boolean) {
    this.send('delete_driver', {
      product: PRODUCT_LABEL,
      Page: 'insured_drivers',
      plan_name: 'TPL',
      category: isRenew ? 'renewal' : null,
    });
  }

  editDriverRenewal() {
    this.send('edit_driver', {
      category: PRODUCT_LABEL,
      product: 'renewal',
      page: 'insured_drivers',
    });
  }

  editDriver(isHolder: boolean, isRenew: boolean) {
    this.send('edit_driver', {
      product: PRODUCT_LABEL,
      plan_name: 'TPL',
      page: 'insured-drivers',
      driver: isHolder ? 'primary' : 'other',
      category: isRenew ? 'renewal' : null,
    });
  }

  addNewDriverRenewal() {
    this.send('added_new_driver', {
      category: PRODUCT_LABEL,
      product: 'renewal',
      page: 'add_driver',
      plan_name: 'TPL',
    });
  }

  addNewDriver() {
    this.send('added_new_driver', {
      product: PRODUCT_LABEL,
      plan_name: 'TPL',
      page: 'add_driver',
      category: null,
    });
  }

  viewQuoteRenewal(
    policyId: Nullable<string>,
    quoteId: string,
    totalPrice: string,
    discount: string,
    quotePrice: string,
  ) {
    this.send('view_quote', {
      category: PRODUCT_LABEL,
      product: 'renewal',
      page: 'your_quote',
      plan_name: 'TPL',
      policy_id: policyId,
      quote_id: quoteId,
      quote_price: quotePrice,
      total_price: totalPrice,
      discount_value: discount,
    });
  }

  viewQuote(policyId: Nullable<string>, quoteId: string, totalPrice: string, isRenew: boolean) {
    this.send('view_quote', {
      product: PRODUCT_LABEL,
      page: 'your_quote',
      category: isRenew ? 'renewal' : null,
      policy_id: policyId,
      quote_id: quoteId,
      plan_name: 'TPL',
      total_price: totalPrice,
    });
  }

  proceedQuoteRenewal(
    policyId: string,
    quoteId: string,
    addons: IdType[],
    totalPrice: string,
    policyStatus: Nullable<string>,
  ) {
    this.send('complete_purchase', {
      category: PRODUCT_LABEL,
      product: 'renewal',
      page: 'your_quote',
      number_of_addons: addons.length,
      list_of_covers: addons.map((item) => item.value),
      plan_name: 'TPL',
      policy_id: policyId,
      transaction_id: quoteId,
      total_price: totalPrice,
      policy_status: policyStatus,
      choosen_card: 'cartc',
    });
  }

  proceedQuote(
    policyId: string,
    quoteId: string,
    addons: IdType[],
    totalPrice: string,
    isRenew: boolean,
  ) {
    this.clickProceed('your_quote', {
      plan_name: 'TPL',
      coupon: '',
      policy_id: policyId,
      quote_id: quoteId,
      number_of_addons: addons.length,
      addons_price: addons.map((item) => item.value),
      total_price: totalPrice,
      chosen_card: 'cartc',
      list_of_addons: addons.map((item) => item.name),
      category: isRenew ? 'renewal' : null,
    });
  }

  switchAddon(
    productCode: string,
    totalPrice: number,
    quoteId: string,
    addonName: string,
    addonPrice: number,
    value: boolean,
  ) {
    this.send('switch_addon', {
      product: PRODUCT_LABEL,
      page: 'quote',
      plan_name: productCode === productCodes.OD ? 'OD' : 'TPL',
      total_price: totalPrice,
      quote_id: quoteId,
      addon_name: addonName,
      addon_price: addonPrice,
      state: value,
    });
  }
  buyNowOnDocuments(
    policyId: Nullable<string>,
    quoteNumber: Nullable<string>,
    numberOfAddons: number,
    addonsPrice: number[],
    totalPrice: number,
    listOfAddons: string[],
  ) {
    this.send('complete_purchase', {
      product: PRODUCT_LABEL,
      plan_name: 'TPL',
      page: 'upload_documents',
      coupon: '',
      policy_id: policyId,
      transaction_id: quoteNumber,
      number_of_addons: numberOfAddons,
      addons_price: addonsPrice,
      total_price: totalPrice,
      chosen_card: 'cartc',
      list_of_addons: listOfAddons,
    });
  }

  buyNowOnQuote(
    policyId: Nullable<string>,
    quoteNumber: Nullable<string>,
    numberOfAddons: number,
    addonsPrice: number[],
    totalPrice: number,
    listOfAddons: string[],
  ) {
    this.send('complete_purchase', {
      product: PRODUCT_LABEL,
      plan_name: 'TPL',
      page: 'your_quote',
      coupon: '',
      policy_id: policyId,
      transaction_id: quoteNumber,
      number_of_addons: numberOfAddons,
      addons_price: addonsPrice,
      total_price: totalPrice,
      chosen_card: 'cartc',
      list_of_addons: listOfAddons,
    });
  }

  clickDownloadDocument(
    title: string,
    rowNumber: number,
    policyId: Nullable<string>,
    quoteId: Nullable<string>,
  ) {
    this.send('click_upload_doc', {
      product: PRODUCT_LABEL,
      title: title,
      row_num: rowNumber,
      plan_name: 'TPL',
      page: 'upload_documents',
      policy_id: policyId,
      quote_id: quoteId,
    });
  }

  uploadDocumentError(
    policyId: string,
    quoteId: string,
    docTitle: string,
    sideTitle: string,
    errorTitle: string,
  ) {
    this.send('input_error', {
      product: PRODUCT_LABEL,
      plan_name: 'TPL',
      page: 'upload_documents',
      policy_id: policyId,
      quote_id: quoteId,
      doc_title: docTitle,
      Side_title: sideTitle,
      Error_title: errorTitle,
    });
  }

  uploadDocumentSuccess(
    policyId: Nullable<string>,
    quoteId: Nullable<string>,
    docTitle: string,
    sideTitle: string,
  ) {
    this.send('succeed_upload_doc', {
      product: PRODUCT_LABEL,
      doc_title: docTitle,
      side_title: sideTitle,
      plan_name: 'TPL',
      page: 'upload_documents',
      policy_id: policyId,
      quote_id: quoteId,
    });
  }

  purchaseDocument(
    policyId: string,
    quoteId: number,
    numberOfAddons: number,
    chosenPlan: string,
    addonsPrice: number[],
    totalPrice: number,
    listOfAddons: string[],
  ) {
    this.send('', {
      product: PRODUCT_LABEL,
      page: 'your_quote',
      coupon: '',
      policy_id: policyId,
      transaction_id: quoteId,
      number_of_addons: numberOfAddons,
      plan_name: 'TPL',
      chosen_plan: chosenPlan,
      addons_price: addonsPrice,
      total_price: totalPrice,
      chosen_card: 'cartc',
      list_of_addons: listOfAddons,
    });
  }

  errorWidgetView(
    errorMessage: string,
    pageName: string | symbol,
    urlBefore?: string,
    title?: string,
    text?: string,
    policyId?: Nullable<string>,
    quoteId?: Nullable<string>,
  ) {
    this.send('error_page', {
      product: PRODUCT_LABEL,
      plan_name: 'TPL',
      page: pageName,
      prev_page: urlBefore,
      title: title,
      text: text,
      policy_id: policyId,
      quote_id: quoteId,
      errorMessage,
    });
  }

  errorPageView(
    pageName: string | symbol,
    urlBefore?: string,
    title?: string,
    text?: string,
    policyId?: Nullable<string>,
    quoteId?: Nullable<string>,
  ) {
    this.send('error_page', {
      product: PRODUCT_LABEL,
      plan_name: 'TPL',
      page: pageName,
      prev_page: urlBefore,
      title: title,
      text: text,
      policy_id: policyId,
      quote_id: quoteId,
    });
  }

  clickWhatsUp(urlBefore: Undefinable<string>, title: string, text: string, isRenewal?: boolean) {
    this.send('click_whatsapp', {
      product: PRODUCT_LABEL,
      prev_page: urlBefore,
      title: title,
      text: text,
      plan_name: 'TPL',
      page: 'enter_info',
      type: 'error_page',
      category: isRenewal ? 'renewal' : null,
    });
  }

  clickCallUs(urlBefore: Undefinable<string>, title: string, text: string, isRenewal?: boolean) {
    this.send('click_callus', {
      product: PRODUCT_LABEL,
      prev_page: urlBefore,
      title: title,
      text: text,
      plan_name: 'TPL',
      page: 'enter_info',
      type: 'error_page',
      category: isRenewal ? 'renewal' : null,
    });
  }
  clickProceedRenewal(policyId: Nullable<string>, license: Nullable<string>) {
    this.send('click_proceed', {
      product: 'renewal',
      category: PRODUCT_LABEL,
      policy_id: policyId,
      page: 'policy_renewal_start',
      license: license,
      plan_name: 'TPL',
    });
  }

  errorProceedRenewal(policyId: Nullable<string>, license: Nullable<string>, errorText: string) {
    this.send('input_error', {
      product: PRODUCT_LABEL,
      text: errorText,
      policy_id: policyId,
      license: license,
      page: 'enter_policy',
    });
  }

  proceedPolicyInfo(policyId: Nullable<string>, license: Nullable<string>) {
    this.send('click_proceed', {
      category: PRODUCT_LABEL,
      product: 'renewal',
      page: 'policy_information',
      policy_id: policyId,
      license: license,
      plan_name: 'TPL',
    });
  }

  policyAlreadyHasRenewed(
    prevUrl: Undefinable<string>,
    title: string,
    text: string,
    policyId: Nullable<string>,
    quoteId: Nullable<number>,
  ) {
    this.send('error_page', {
      product: PRODUCT_LABEL,
      category: 'renewal',
      Page: 'policy_info',
      prev_page: prevUrl,
      title: title,
      text: text,
      policy_id: policyId,
      quote_id: quoteId,
    });
  }

  clickContactUs(
    isRenewal: boolean,
    page: string,
    policyId: Nullable<string>,
    quoteId: Nullable<string>,
  ) {
    this.send('contact_us', {
      product: PRODUCT_LABEL,
      category: isRenewal ? 'renewal' : null,
      plan_name: 'TPL',
      page: (routingMatches as Record<string, string>)[page],
      policy_id: policyId,
      quote_id: quoteId,
    });
  }

  clickSeeAllCovers(entrypoint: string, productCode?: string, price?: number) {
    this.send('click_see_all_covers', {
      product: PRODUCT_LABEL,
      page: 'select_plan',
      item_name: productCode === productCodes.OD ? 'Comprehensive' : 'TPL',
      flow: entrypoint,
      price,
    });
  }

  viewPlateNumber() {
    this.send('view_page', {
      product: PRODUCT_LABEL,
      page: 'enter_info',
    });
  }

  switchOffPlateNumber() {
    this.send('swtich_off_plate_number', {
      product: PRODUCT_LABEL,
      page: 'enter_info',
    });
  }

  switchOnPlateNumber() {
    this.send('swtich_on_plate_number', {
      product: PRODUCT_LABEL,
      page: 'enter_info',
    });
  }

  viewDateOfBirth() {
    this.send('view_page', {
      product: PRODUCT_LABEL,
      page: 'date_of_birth',
    });
  }

  viewCheckCarDetails(
    chassisNumber: string,
    plateNumber: string,
    brand: string,
    model: string,
    modelYear: Nullable<number>,
    registrationYear: Nullable<number>,
    vehicleUsage: string,
    vehicleType: string,
    seats: string,
    cylinders: string,
  ) {
    this.send('view_page', {
      plan_name: 'TPL',
      product: PRODUCT_LABEL,
      chassis_number: chassisNumber,
      plate_number: plateNumber,
      make: brand,
      model,
      model_year: modelYear,
      registration_year: registrationYear,
      vehicle_usage: vehicleUsage,
      vehicle_type: vehicleType,
      seats,
      cylinders,
    });
  }

  viewCarDetails(
    brand: string,
    model: string,
    modelYear: Nullable<number>,
    registrationYear: Nullable<number>,
    vehicleUsage: string,
    vehicleType: string,
    seats: string,
    cylinders: string,
    chassisNumber: string,
  ) {
    this.send('view_page', {
      plan_name: 'TPL',
      product: PRODUCT_LABEL,
      chassis_number: chassisNumber,
      make: brand,
      model,
      model_year: modelYear,
      registration_year: registrationYear,
      vehicle_usage: vehicleUsage,
      vehicle_type: vehicleType,
      seats,
      cylinders,
    });
  }

  validationError(page: string, errors: string | object, names?: string, planName = 'TPL') {
    this.send('input_error', {
      product: PRODUCT_LABEL,
      page,
      text: errors,
      input_field: names,
      plan_name: planName,
    });
  }

  viewErrorPage(
    page: string,
    quoteId: Nullable<string>,
    policyId: Nullable<string>,
    planeName: string,
    title: string,
    text: string,
  ) {
    this.send('error_page', {
      product: PRODUCT_LABEL,
      category: 'renewal',
      page: page,
      quote_id: quoteId,
      policy_id: policyId,
      plan_name: planeName,
      title: title,
      text: text,
      error_message: text,
    });
  }

  proceedRouteMainPage(planName: string) {
    this.send('click_main_page', {
      product: PRODUCT_LABEL,
      category: 'renewal',
      page: 'policy_active_soon',
      plan_name: planName,
    });
  }

  proceedRouteNewPolicy(planName: string) {
    this.send('click_buy_new_policy', {
      product: PRODUCT_LABEL,
      category: 'renewal',
      page: 'can_buy_new_policy',
      plan_name: planName,
    });
  }

  viewInfoPage(
    page: string,
    quoteId: Nullable<string>,
    policyId: Nullable<string>,
    planeName: string,
    title: string,
    text: string,
  ) {
    this.send('info_page', {
      product: PRODUCT_LABEL,
      category: 'renewal',
      page: page,
      quote_id: quoteId,
      policy_id: policyId,
      plan_name: planeName,
      title: title,
      text: text,
      error_message: text,
    });
  }

  onSelectDOBRenewal(value: string) {
    this.send('type_dob', {
      category: PRODUCT_LABEL,
      product: 'renewal',
      page: 'policyholder_info',
      value: value,
    });
  }

  onTypedName(value: string) {
    this.send('type_name', {
      category: PRODUCT_LABEL,
      product: 'renewal',
      page: 'policyholder_info',
      value: value,
    });
  }

  onTypedFamilyName(value: string) {
    this.send('type_surname', {
      category: PRODUCT_LABEL,
      product: 'renewal',
      page: 'policyholder_info',
      value: value,
    });
  }

  viewCarValue(value: number, min: number, max: number) {
    this.send('view_page', {
      product: PRODUCT_LABEL,
      page: 'car_value',
      vehicle_value: value,
      vehicle_low_value: min,
      vehicle_high_value: max,
    });
  }

  saveCarValue(value: number) {
    this.clickProceed('car_value', {
      value: value,
    });
  }

  viewCoverages(quotes: QuoteType[], productCode: string, totalPrice?: number) {
    const items = quotes.map((item) => ({
      item_name: item.geoArea,
      price: item.schemes.find((scheme) => scheme.productcode === productCode)?.price,
      item_variant: productCode === productCodes.OD ? 'OD' : 'TPL',
    }));

    this.send('view_item', {
      product: PRODUCT_LABEL,
      page: 'coverage_area',
      total_price: totalPrice,
      items: items,
    });
  }

  selectCoverage(productCode: string, geoAreaCode: string, totalPrice?: number) {
    this.send('select_item', {
      product: PRODUCT_LABEL,
      page: 'coverage_area',
      total_price: totalPrice,
      items: [
        {
          item_name: regionNames[geoAreaCode],
          price: totalPrice,
          item_variant: productCode === productCodes.OD ? 'OD' : 'TPL',
        },
      ],
    });
  }

  clickCoverageInfo(geoAreaCode: string) {
    this.send('click_link', {
      product: PRODUCT_LABEL,
      page: 'coverage_area',
      link_text: 'question_mark',
      selected_area: regionNames[geoAreaCode],
    });
  }

  submitCoverage(geoAreaCode: string, productCode: string, totalPrice?: number) {
    this.clickProceed('coverage_area', {
      plan_name: productCode === productCodes.OD ? 'OD' : 'TPL',
      price: totalPrice,
      selected_area: regionNames[geoAreaCode],
    });
  }

  switchGccSpec(value: boolean) {
    this.send('switch_toggle', {
      product: PRODUCT_LABEL,
      page: 'car_details',
      type: 'gcc_spec_car',
      state: value,
    });
  }

  onlyOfflineErrorView(
    pageName: string,
    urlBefore: string,
    title: string,
    text: string,
    quoteId: string,
    errorName: string,
  ) {
    this.send('error_page', {
      product: PRODUCT_LABEL,
      title: title,
      text: text,
      prev_page: urlBefore,
      quote_id: quoteId,
      page: pageName,
      error_code: errorName,
    });
  }
}
