import { createApp } from 'vue';
import * as Sentry from '@sentry/vue';

import '@qic/ui-components/style.css';
import '@/shared/assets/styles/main.scss';

import App from './App.vue';
import router from './router';
import i18n from './plugins/i18n';
import pinia from './plugins/pinia';
import recaptcha from './plugins/recaptcha/recaptcha';

const app = createApp(App);

if (import.meta.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: import.meta.env.VUE_APP_SENTRY_DSN,
    integrations: [
      // Пока отключил 400 ошибки в сентри. Можно будет удалить, если эти ошибки не понадобятся
      // Sentry.httpClientIntegration({
      //   failedRequestStatusCodes: [400, 599],
      // }),
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', import.meta.env.VUE_APP_PUBLIC_API_URL],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

app.use(router);
app.use(pinia);
app.use(i18n);
if (import.meta.env.VUE_APP_SITE_KEY) {
  app.use(recaptcha, { siteKey: import.meta.env.VUE_APP_SITE_KEY });
}
app.provide('locale', i18n.global.locale.value);

app.mount('#app');
