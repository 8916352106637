import type { GeoCods } from '@/shared/types/flowStore.types';

export const bodyCodeMapping: { [key: number]: string } = {
  1: '1001',
  2: '1002',
  3: '1003',
};

export const ACTIVE_VEHICLE_TYPES = ['1001', '1002', '1003'];

export const VEHICLE_USAGE_PRIVATE_CODE = '1001';

export const POPUP_NAME = {
  GCC: 'GCC',
  DEDUCTIBLE: 'Deductible',
  NO_DEDUCTIBLE: 'NoDeductible',
  LEARN_MORE: 'Learn More',
};

export const CHASSIS_LENGTH = 17;
export const PLATE_NUMBER_LENGTH = 6;

export const AREA_CODE_MAP: Record<string, GeoCods> = {
  OMAN: '6001',
  OMAN_UAE: '6002',
  OMAN_GCC: '6004',
};

export const productCodeMap = {
  OD: '0110',
  TPL: '0100',
};

// TODO: perhaps it's shared
export const NOT_FOUND_BY_PLATE = 'plate_not_found';
export const NOT_FOUND_BY_CHASSIS = 'no_records_found';
export const NOT_FOUND_RECORDS = 'no_records_found';
export const WRONG_DOB = 'wrong_DOB';
export const WRONG_VEHICLE_USAGE = 'wrong_vehicle_usage';
export const FOUND_ACTIVE_POLICY = 'found_active_policy';
export const WRONG_VEHICLE_MAKE = 'wrong_vehicle_make';
export const WRONG_VEHICLE_MODEL = 'wrong_vehicle_model';
export const WRONG_VEHICLE_MODEL_YEAR = 'wrong_vehicle_model_year';
export const RATE_LIMIT = 'rate_limit_exceedance';
export const CAPTCHA_ERROR = 'orcaptcha_verification_failed';
export const WRONG_ADMEID = 'wrong_admeId';
export const NOT_PRIVATE_USAGE = 'not_private_usage';
export const WRONG_CHASSIS_NUMBER = 'wrong_chassis_number';
export const POLICY_HOLDER_TOO_YOUNG = 'policyholder_too_young';
export const POLICY_HOLDER_TOO_OLD = 'policyholder_too_old';
export const WRONG_DRIVING_EXP = 'wrong_driving_exp';
export const INVALID_QID = 'invalid_qid';
export const POLICY_NOT_FOUND = 'policy_not_found';

export const VEHICLE_DESCRIPTION_ERROR_LIST = [
  FOUND_ACTIVE_POLICY,
  WRONG_VEHICLE_MAKE,
  WRONG_VEHICLE_MODEL,
  WRONG_VEHICLE_MODEL_YEAR,
  WRONG_VEHICLE_USAGE,
  WRONG_ADMEID,
];
